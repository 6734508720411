"use client";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Room, Workspace } from "@/db/schema";
import { useSheetSide } from "@/hooks/useSheetSide";
import { createEvent } from "@/lib/createEvent";
import { useAuth } from "@clerk/nextjs";
import clsx from "clsx";
import { on } from "events";
import { Loader2 } from "lucide-react";
import { useRouter } from "next/navigation";
import React, { useEffect, useMemo, useState } from "react";
import { useFormState, useFormStatus } from "react-dom";
import { toast } from "sonner";

const initialState = {
  message: "",
  error: "",
  count: 0,
};

function isValidDate(d: Date) {
  return d instanceof Date && !isNaN(d.getTime());
}

export function CalendarBlock({
  index,
  room,
  workspace,
  from,
}: {
  index: number;
  room: Room;
  workspace: Workspace;
  from: Date;
}) {
  const [state, formAction] = useFormState(createEvent, initialState);
  const [prevCount, setPrevCount] = useState(0);
  const [open, setOpen] = useState(false);
  const side = useSheetSide();
  const router = useRouter();
  const { orgRole } = useAuth();

  const [_startTime, setStartTime] = useState(new Date());
  const [_endTime, setEndTime] = useState(new Date());

  const startTime = useMemo(() => {
    return isValidDate(_startTime)
      ? _startTime
      : new Date(from.getFullYear(), from.getMonth(), from.getDate(), 0); // Return 00:00 if date is not valid
  }, [_startTime, from]);

  const endTime = useMemo(() => {
    return isValidDate(_endTime)
      ? _endTime
      : new Date(from.getFullYear(), from.getMonth(), from.getDate(), 0); // Return 00:00 if date is not valid
  }, [_endTime, from]);

  const startTimeRef = React.useRef<HTMLInputElement>(null);
  const endTimeRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    const hour = Math.floor(index / 2) + workspace.openHour;
    const minute = (index % 2) * 30;
    const _startTime = new Date(from);
    _startTime.setHours(hour, minute);

    const _endTime = new Date(_startTime);
    _endTime.setMinutes(_endTime.getMinutes() + 30);

    setStartTime(_startTime);
    setEndTime(_endTime);
  }, [from, index, workspace.openHour]);

  const onChangeStartTime = (time: string) => {
    const [hours, minutes] = time.split(":");
    const s = new Date(startTime);
    s.setHours(parseInt(hours), parseInt(minutes));
    setStartTime(s);
  };

  const onChangeEndTime = (time: string) => {
    const [hours, minutes] = time.split(":");
    const e = new Date(endTime);
    e.setHours(parseInt(hours), parseInt(minutes));
    setEndTime(e);
  };

  const isAdmin = useMemo(() => {
    return orgRole === "admin";
  }, [orgRole]);

  useEffect(() => {
    if (state.message && state.count > prevCount) {
      toast.success(state.message);
      setOpen(false);
      router.refresh();
      setPrevCount(state.count);
    } else if (state.error && state.count > prevCount) {
      toast.error(state.error);
      setPrevCount(state.count);
    }
  }, [state.message, state.count, state.error, prevCount, router]);

  const bookable = useMemo(() => {
    return isAdmin || room.bookableByPublic || !workspace.requireAuth;
  }, [isAdmin, room.bookableByPublic, workspace.requireAuth]);

  return (
    <>
      <div
        onClick={() => {
          if (bookable) setOpen(true);
        }}
        style={{ gridRow: index + 1, height: "var(--block-height)" }}
        className={clsx(
          "col-span-1 md:col-start-1 col-start-2 ",
          bookable && "hover:bg-gray-100 cursor-pointer"
        )}
      ></div>
      <Sheet open={open} onOpenChange={setOpen}>
        <SheetContent
          side={side}
          className="text-start justify-start items-start"
        >
          <SheetHeader className="text-start space-y-0">
            <SheetTitle>
              Book <span className="text-blue-500">{room.name}</span>
            </SheetTitle>
            <SheetDescription>
              <span className="capitalize">
                {startTime.toLocaleDateString("en-GB", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </span>
            </SheetDescription>
          </SheetHeader>
          <form
            action={formAction}
            className="flex flex-col gap-2 md:gap-4 my-4"
          >
            <div className="flex flex-col gap-2">
              <Label htmlFor="name">Title</Label>
              <Input
                id="title"
                name="title"
                placeholder="ex. Morning meeting"
                className="col-span-3"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="authorName">Booked by</Label>
              <Input
                id="authorName"
                name="authorName"
                placeholder="ex. John Doe"
                className="col-span-3"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="authorCompany">Company</Label>
              <Input
                id="authorCompany"
                name="authorCompany"
                placeholder="ex. E-corp"
                className="col-span-3"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="startTime">Start time</Label>
              <Input
                type="time"
                placeholder=""
                value={startTime.toTimeString().slice(0, 5)}
                className="col-span-3"
                onChange={(e) => {
                  onChangeStartTime(e.target.value);
                }}
              />
              <input
                type="hidden"
                name="startTime"
                value={startTime.toISOString()}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="endTime">End time</Label>
              <Input
                ref={endTimeRef}
                type="time"
                placeholder=""
                value={endTime.toTimeString().slice(0, 5)}
                className="col-span-3"
                onChange={(e) => {
                  const value = e.target.value;

                  // Check if the value is empty or not in HH:MM format
                  if (
                    !value ||
                    !/^(?:[01][0-9]|2[0-3]):[0-5][0-9]$/.test(value)
                  ) {
                    // Handle invalid or empty input. Options:
                    // 1. Set a default value
                    // onChangeEndTime("default-time-here");
                    // 2. Clear the input or handle as needed
                    // onChangeEndTime("");
                    // For demonstration, let's clear the endTime
                    onChangeEndTime("");
                  } else {
                    // Value is valid, proceed with your original logic
                    onChangeEndTime(value);
                  }
                }}
              />
              <input
                type="hidden"
                name="endTime"
                value={endTime.toISOString()}
              />
            </div>

            <div className="flex flex-row gap-1">
              <Button
                variant={"ghost"}
                type="button"
                onClick={() => {
                  setEndTime(new Date(startTime.getTime() + 30 * 60 * 1000));
                }}
              >
                30min
              </Button>
              <Button
                variant={"ghost"}
                type="button"
                onClick={() => {
                  setEndTime(new Date(startTime.getTime() + 60 * 60 * 1000));
                }}
              >
                1h
              </Button>
              <Button
                variant={"ghost"}
                type="button"
                onClick={() => {
                  setEndTime(new Date(startTime.getTime() + 90 * 60 * 1000));
                }}
              >
                1h 30min
              </Button>
              <Button
                variant={"ghost"}
                type="button"
                onClick={() => {
                  setEndTime(new Date(startTime.getTime() + 120 * 60 * 1000));
                }}
              >
                2h
              </Button>
            </div>

            <input type="hidden" name="roomId" value={room.id} />
            <input type="hidden" name="date" value={startTime.toISOString()} />

            <SubmitButton />
          </form>
        </SheetContent>
      </Sheet>
    </>
  );
}

const SubmitButton = () => {
  const { pending } = useFormStatus();

  return (
    <Button type="submit" disabled={pending}>
      {pending && <Loader2 className="animate-spin mr-2" />}
      Create booking
    </Button>
  );
};
